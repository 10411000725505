
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import SelectionContent from '@/pages/OrderFlow/OrderOverviewPage/Content/DaySelectionContent/SelectionContent.vue'
import PriceSummary from '@/pages/OrderFlow/OrderOverviewPage/Content/PriceSummary/PriceSummary.vue'
import AcceptWaitingListActions from '@/pages/OrderFlow/OrderOverviewPage/Content/AcceptWaitingList/AcceptWaitingListActions.vue'
import { GETTERS, NAMESPACES, STATE, ACTIONS, MUTATIONS } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { DayOfWeek, GetWaitingListStatusOutput, Proposition, ServiceKind } from '@/models'
import OfferService from '@/services/OfferService/OfferService'
import { ROUTES } from '@/router/routes'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import ProductCategorySelect from './Content/ProductCategorySelect/ProductCategorySelect.vue'
import OfferNotAvailableContent from './Content/OfferNotAvailableContent/OfferNotAvailableContent.vue'
import { timeBetween, unitOfTime } from '@/utils/dateUtils'
import Page from '@/pages/Page'
import i18n from '@/plugins/i18n'
import SharingMenu from '@/components/SharingMenu/SharingMenu.vue'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import SchoolSelectionForm from '@/components/AvailabilitySelector/SchoolSelectionForm.vue'
import WaitingListPlacePriority from '@/models/enums/WaitingListPlacePriority'
import { SchoolValidationResponse } from '@/store/modules/userSelection/ValidatorEnums/SchoolValidationResponse'
import DaySelectionState from '@/models/types/DaySelectionState'
import { debounce } from 'lodash'
import WaitingListPartiallyDeclinedDialog from '@/pages/OrderFlow/OrderOverviewPage/Content/DaySelectionContent/WaitingListPartiallyDeclinedDialog.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import ProductService from '@/services/ProductService/ProductService'
import { ProductCategoryViewData } from '@/services/ProductService/ProductCategoryViewData'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import ServiceVarietyName, { sortedServiceVarietyNames } from '@/models/enums/ServiceVarietyName'
import PropositionSubscription from '@/models/PropositionSubscription'
import UnprocessablePropositionSubscriptionError from '@/services/PropositionService/UnprocessablePropositionSubscriptionError'
import { WATITING_LIST_PROPOSITION } from '@/constants/constants'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: {
    PageHeader,
    PartouFloatingCircleButton,
    PartouDialog,
    SelectionContent,
    ProductCategorySelect,
    OfferNotAvailableContent,
    PartouLoader,
    SharingMenu,
    PriceSummary,
    SchoolSelectionForm,
    AcceptWaitingListActions,
    WaitingListPartiallyDeclinedDialog,
    PartouAutocomplete
  },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class OrderOverviewSelectProductPage extends Vue implements Page {
  pageTitle = ''

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.State(STATE.userSelection.formIsValid)
  userSelectionFormIsValid!: boolean

  @propositionModule.State(STATE.proposition.waitinglistPropositionStatus)
  waitinglistPropositionStatus?: GetWaitingListStatusOutput

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @propositionModule.Getter(GETTERS.proposition.getPropositionById)
  getPropositionById!: (id: string) => Proposition | undefined

  @propositionModule.Mutation(MUTATIONS.proposition.updateProposition)
  updateProposition!: (proposition : Partial<Proposition>) => void

  @propositionModule.Action(ACTIONS.proposition.getPropositionSubscriptionsAsync)
  getPropositionSubscriptionsAsync!: (offer: { offerId: string, productIds?: string[] }) => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getWaitingListPropositionStatusAsync)
  getWaitingListPropositionStatus!: (bookingHash: string) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setStartDate)
  setStartDate!: (startDate : Date) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours : boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxStateWaitingListProposition)
  getDayCheckboxStateWaitingListProposition!: () => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchool)
  getIsValidSchool!: () => SchoolValidationResponse

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolGroup)
  getIsValidSchoolGroup!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  @userSelectionModule.Action(ACTIONS.userSelection.setServiceVarietyDaysAsync)
  setServiceVarietyDaysAsync!: (input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) => Promise<void>

  @configurationModule.State(STATE.configuration.configuration)
  configuration!: Record<string, any> /* Disabled because of any */// eslint-disable-line

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedProductIdsForServiceAsync)
  setSelectedProductIdsForServiceAsync!: (selectableProductIds : string[]) => Promise<void>

  @Prop({ required: false })
  bookingHash!: string

  @Prop({ required: false })
  showLinkToPartouOffers!: boolean

  @Prop({ required: true })
  serviceSlug!: string

  proposition? : Partial<Proposition>
  showOverlay = true
  showPartiallyAcceptedProposition = false
  declineEntireOffer = false
  declinedDistinctDays: (DayOfWeek | undefined)[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declinedDaysFromProposition: any = { NSO: [], VSO: [], KDV: [] }
  serviceVarietiesInProposition: ServiceVarietyName[] = []
  isLoadingProposition = false
  isRefreshingPrices = false
  isLoadingCategories = false

  roundingClass = 'rounded-xl'
  productCategories: ProductCategoryViewData[] = []
  displayVSOProducts = false

  async created () : Promise<void> {
    if (this.userSelectionFormState.isAcceptingWaitinglist) {
      this.isLoadingProposition = true
      await this.getWaitingListPropositionStatus(this.bookingHash)
      if (!this.waitinglistPropositionStatus || this.waitinglistPropositionStatus.isExpired) {
        this.$router.push({ name: ROUTES.waitingListOfferExpired, params: { spotAvailableDurationInMinutes: (this.waitinglistPropositionStatus?.spotAvailableDurationInMinutes ?? '').toString() } })
      } else if (this.waitinglistPropositionStatus.finishBookingRegistration) {
        this.$router.push({ name: ROUTES.bookingStatus, params: { bookingHash: this.bookingHash } })
      } else if (this.waitinglistPropositionStatus) {
        if (this.waitinglistPropositionStatus.startDate) {
          this.setStartDate(new Date(this.waitinglistPropositionStatus.startDate))
        }
        sortedServiceVarietyNames.forEach(serviceVarietyName => {
          const days = this.userSelectionFormState.daysPerServiceVariety[serviceVarietyName]
          for (const day in DayOfWeek) {
            const availableDays = this.waitinglistPropositionStatus?.availableWaitingListDays[serviceVarietyName]
            if (availableDays?.find((d: { dayOfWeek: string }) => d.dayOfWeek === day)) {
              const activeDay = days.find(x => x.day === day)
              if (activeDay) {
                activeDay.isChecked = true
              }
            }
          }
          this.setServiceVarietyDaysAsync({ serviceVariety: serviceVarietyName, days })
        })
      }
      this.serviceVarietiesInProposition = this.userSelectionFormState.selectedServiceVarieties
      this.isLoadingProposition = false
    }

    await this.getPropositionOrOffer()
    if (!this.userSelectionFormState.isAcceptingWaitinglist) {
      await this.getProductsAsync()
    }
  }

  mounted () : void {
    window.addEventListener('scroll', this.updateViewByWindow)
    window.addEventListener('resize', debounce(this.updateViewByWindow, 100))
    window.addEventListener('touchmove', this.updateViewByWindow)
    this.updateViewByWindow()

    this.pageTitle = this.userSelectionFormState.isAcceptingWaitinglist ? i18n.t('pageTitles.orderOverviewSelectProductAcceptingWaitinglist').toString() : i18n.t('pageTitles.orderOverviewSelectProduct').toString()
  }

  updated (): void {
    this.updateViewByWindow()
  }

  beforeDestroy () : void {
    window.removeEventListener('scroll', this.updateViewByWindow)
    window.removeEventListener('resize', this.updateViewByWindow)
    window.removeEventListener('touchmove', this.updateViewByWindow)
  }

  get sharingTitle () : string {
    return this.proposition?.name ?? ''
  }

  get showNotBookable (): boolean {
    return this.userSelectionFormState.selectedServiceKind !== ServiceKind.SchoolCare ||
           !!this.userSelectionFormState.selectedSchoolGroup
  }

  get isOrderButtonDisabled () : boolean {
    const validFormState = this.userSelectionFormIsValid
    const validSelection = this.isValidOrder && this.propositionContainsAnyServiceVarieties()
    const validSelectedProducts = !this.showCategories || (this.userSelectionFormState.selectedProductIds !== undefined && this.userSelectionFormState.selectedProductIds.length !== 0)

    return !validFormState || !validSelection || !validSelectedProducts
  }

  get isAcceptWaitingListOrderButtonDisabled () : boolean {
    const isSomeCheckedDays = Object.values(this.getDayCheckboxStateWaitingListProposition()).some(x => x.some(y => y.isChecked))
    const isSchoolSelected = this.userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare ? this.getIsValidSchool() && this.getIsValidSchoolGroup() : true

    const validSelectedProducts = !this.showCategories || (this.userSelectionFormState.selectedProductIds !== undefined && this.userSelectionFormState.selectedProductIds.length !== 0)
    return !isSomeCheckedDays || !isSchoolSelected || !validSelectedProducts
  }

  propositionContainsAnyServiceVarieties () : boolean {
    return (this.proposition !== undefined &&
            this.proposition.serviceVarieties !== undefined &&
            this.proposition.serviceVarieties?.length > 0)
  }

  get isValidOrder (): boolean {
    return this.orderHasValidDayCareSelection || this.orderHasValidSchoolCareSelection
  }

  get orderHasValidSchoolCareSelection () : boolean {
    return !!(this.userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare &&
              this.userSelectionFormState.daysPerServiceVariety.NSO &&
              this.userSelectionFormState.daysPerServiceVariety.NSO.some(x => x.isChecked))
  }

  get orderHasValidDayCareSelection () : boolean {
    return !!(this.userSelectionFormState.selectedServiceKind === ServiceKind.DayCare &&
              this.userSelectionFormState.daysPerServiceVariety.KDV &&
              this.userSelectionFormState.daysPerServiceVariety.KDV.some(x => x.isChecked))
  }

  get hasWaitingListDays () : boolean {
    if (!this.proposition || !this.proposition.id) {
      return false
    }

    const checkboxStates = this.getDayCheckboxState(true, true)
    return Object.values(checkboxStates).some(x => x.some(y => y.isChecked && y.type === DayCheckboxType.Wait))
  }

  get showSchoolComponent (): boolean {
    return !!this.userSelectionFormState.isAcceptingWaitinglist && this.userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare
  }

  get showPricing () : boolean {
    return !this.userSelectionFormState.isAcceptingWaitinglist ||
           (this.waitinglistPropositionStatus?.priority !== WaitingListPlacePriority.OwnStaff &&
            this.waitinglistPropositionStatus?.priority !== WaitingListPlacePriority.Smi)
  }

  get showCategories () : boolean {
    return !this.userSelectionFormState.isAcceptingWaitinglist
  }

  @Watch('userSelectionFormState.selectedSchool')
  @Watch('userSelectionFormState.selectedSchoolGroup')
  async onSchoolSelected () : Promise<void> {
    if (this.getIsValidSchool() === SchoolValidationResponse.Success && this.getIsValidSchoolGroup()) {
      await this.getProductsAsync()
    } else {
      this.productCategories = []
    }
  }

  parseTimeToAmountOfSeconds (timeString : string) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds // convert to seconds
  }

  async getProductsAsync () {
    this.isLoadingCategories = true
    if (this.proposition && this.proposition.id) {
      const productService : ProductService = container.get(SERVICE_IDENTIFIERS.IProductService) // Note: @inject does not work for some reason, so this is a workaround
      const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)

      const productCategories = await productService.getActivatedServiceProducts(this.proposition?.id ?? '', this.userSelectionFormState.startDateOfDayCare ?? new Date())
      productCategories.sort((a, b) => a.name.localeCompare(b.name))
      productCategories.forEach(p => p.products.sort((a, b) =>
        this.parseTimeToAmountOfSeconds(a.startTime) - this.parseTimeToAmountOfSeconds(b.startTime) ||
        this.parseTimeToAmountOfSeconds(a.endTime) - this.parseTimeToAmountOfSeconds(b.endTime)
      ))

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const productCategoryFeatures = await configurationService.getConfigurationByKeyAsync<any>({ key: 'product_category_features' })
      this.productCategories = productCategories.map(p => ({ ...p, features: productCategoryFeatures[p.name] }))
    } else {
      this.productCategories = []
    }
    this.displayVSOProducts = this.getSelectedServiceVarieties().indexOf(ServiceVarietyName.VSO) !== -1
    this.isLoadingCategories = false
  }

  @Watch('userSelectionFormState.selectedProductIds')
  async getPropositionOrOffer (productId?: string) : Promise<void> {
    this.isLoadingProposition = productId === undefined
    this.isRefreshingPrices = true
    await this.getPropositionsAsync()
    if (this.userSelectionFormState.selectedPropositionId) {
      this.proposition = this.getPropositionById(this.userSelectionFormState.selectedPropositionId)
    }
    if (!this.proposition) {
      const offerService : OfferService = container.get(SERVICE_IDENTIFIERS.IOfferService) // Note: @inject does not work for some reason, so this is a workaround
      this.proposition = await offerService.getOfferBySlugAsync({ slug: this.serviceSlug, dateOfOpeningTime: this.userSelectionFormState.startDateOfDayCare }) as Proposition
      if (this.proposition) {
        this.updateProposition(this.proposition)
      }
    }

    if (this.proposition && this.proposition.id) {
      try {
        await this.getPropositionSubscriptionsAsync({ offerId: this.proposition.id, productIds: this.userSelectionFormState.selectedProductIds })
      } catch (e) {
        if (!(e instanceof UnprocessablePropositionSubscriptionError)) {
          throw e
        }
      }
    }

    this.isRefreshingPrices = false
    this.isLoadingProposition = false
  }

  isOfferAvailable () : boolean {
    if (this.userSelectionFormState.isAcceptingWaitinglist) {
      return true
    }

    if (!this.userSelectionFormState.dateOfBirth || !this.userSelectionFormState.startDateOfDayCare) {
      return false
    }

    const currentAge = Math.floor(timeBetween(this.userSelectionFormState.dateOfBirth, this.userSelectionFormState.startDateOfDayCare, unitOfTime.year))
    if ((currentAge >= 4 && !this.userSelectionFormState.selectedSchoolGroup) || this.productCategories.length <= 0) {
      return false
    }
    return true
  }

  getDefaultSubscription () : PropositionSubscription | undefined {
    return this.proposition?.propositionSubscriptions?.[0]
  }

  onBackButtonClicked () : void {
    this.$router.push({ name: ROUTES.locationDetail, params: { serviceSlug: this.serviceSlug } })
  }

  scrollToBottom () : void {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
  }

  updateViewByWindow () : void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 60) {
      this.showOverlay = false
    } else {
      this.showOverlay = true
    }
  }

  async onOrderClickedAsync () : Promise<void> {
    this.declineEntireOffer = false
    if (this.userSelectionFormState.isAcceptingWaitinglist) {
      this.getDeclinedDaysFromProposition()
    }
    if (this.declinedDaysFromProposition.NSO.length === 0 && this.declinedDaysFromProposition.VSO.length === 0 && this.declinedDaysFromProposition.KDV.length === 0) {
      this.$router.push({ name: ROUTES.orderContactInfo, params: { serviceSlug: this.serviceSlug, bookingHash: this.bookingHash } })
      return
    }

    this.showPartiallyAcceptedProposition = true
  }

  async onDeclineButtonClickedAsync () : Promise<void> {
    this.getAllDaysFromProposition()
    this.declineEntireOffer = true
    this.showPartiallyAcceptedProposition = true
  }

  getAllDaysFromProposition () : void {
    const dayStateNSO: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety.NSO
    const filteredNSO = dayStateNSO.filter(o => o.startDate !== undefined && o.startDate.toLowerCase() !== WATITING_LIST_PROPOSITION.NOT_AVAILABLE_TEXT).map(o => o.day)
    const dayStateVSO: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety.VSO
    const filteredVSO = dayStateVSO.filter(o => o.startDate !== undefined && o.startDate.toLowerCase() !== WATITING_LIST_PROPOSITION.NOT_AVAILABLE_TEXT).map(o => o.day)
    const dayStateKDV: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety.KDV
    const filteredKDV = dayStateKDV.filter(o => o.startDate !== undefined && o.startDate.toLowerCase() !== WATITING_LIST_PROPOSITION.NOT_AVAILABLE_TEXT).map(o => o.day)

    this.declinedDaysFromProposition = {
      NSO: filteredNSO,
      VSO: filteredVSO,
      KDV: filteredKDV
    }
  }

  getDeclinedDaysFromProposition () : void {
    this.declinedDaysFromProposition = {
      NSO: [],
      VSO: [],
      KDV: []
    }

    this.serviceVarietiesInProposition.forEach(serviceVariety => {
      const dayState: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety[serviceVariety]

      // Special treatment for VSO checkbox state -> isDisabled property did not update correctly based on NSO daystate
      if (serviceVariety === ServiceVarietyName.VSO) {
        const dayStateNSO: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety.NSO
        dayStateNSO.forEach(dayNSO => {
          if (dayNSO.isChecked) {
            const vsoDay = dayState.find(dayVSO => dayVSO.day === dayNSO.day)
            if (vsoDay && vsoDay.startDate !== undefined && vsoDay.startDate.toLowerCase() !== WATITING_LIST_PROPOSITION.NOT_AVAILABLE_TEXT) {
              vsoDay.isDisabled = false
            }
          }
        })

        const filtered = dayState.filter(o => !o.isChecked && o.startDate !== undefined && o.startDate !== WATITING_LIST_PROPOSITION.NOT_AVAILABLE_TEXT).map(o => o.day)
        this.declinedDaysFromProposition[serviceVariety] = filtered
      } else {
        const filtered = dayState.filter(o => !o.isChecked && !o.isDisabled).map(o => o.day)
        this.declinedDaysFromProposition[serviceVariety] = filtered
      }
    })
  }

  closePartialDeclineDialog () : void {
    this.showPartiallyAcceptedProposition = false
  }
}
