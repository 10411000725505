
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import ServicesMap from '@/components/ServicesMap/ServicesMap.vue'
import AvailabilitySelector from '@/components/AvailabilitySelector/AvailabilitySelector.vue'
import ServiceCard from '@/components/ServiceCard/ServiceCard.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import { NAMESPACES, STATE, ACTIONS, GETTERS, MUTATIONS } from '@/store'
import { namespace } from 'vuex-class'
import { Proposition, Feature, ServiceKind, Service, ServiceVarietyName } from '@/models'
import OpenAvailabilitySelectorButton from '@/components/AvailabilitySelector/OpenAvailabilitySelectorButton.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { eventBus } from '@/EventBus'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import ICalculatorFormState from '@/store/modules/childBenefitCalculator/ICalculatorFormState'
import { getServiceKindParamFromServiceKind, ROUTES } from '@/router/routes'
import Page from '@/pages/Page'
import i18n from '@/plugins/i18n'
import { sendChooseLocationAnalyticsEvent, sendLocationSearchAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import UnprocessablePropositionSubscriptionError from '@/services/PropositionService/UnprocessablePropositionSubscriptionError'
import Debounce from '@/utils/decorators/debounceDecorator'
import DateValidationPopups from '@/components/AvailabilitySelector/DateValidationPopups.vue'
import Availability from '@/models/enums/Availability'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)
const defaultFeatureModule = namespace(NAMESPACES.defaultFeature)
const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)

@Component({
  components: { PartouNotify, DateValidationPopups, PartouCard, PageHeader, ServicesMap, ServiceCard, PartouButton, PartouFloatingCircleButton, PartouLoader, MultiStateDaySelector, AvailabilitySelector, OpenAvailabilitySelectorButton },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'description', content: (this as any).metaDescription }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'index, follow' }
      ]
    }
  }
})

export default class ServiceOverviewPage extends Vue implements Page {
  @Prop({ required: false, default: '' })
  locality!: string

  @Prop({ required: false })
  serviceKind?: ServiceKind

  pageTitle = i18n.t('pageTitles.locations', { serviceVarietyName: this.getTranslatedServiceKindString(), locality: this.locality }).toString()
  metaDescription = i18n.t('pageDescriptions.locations', { serviceVarietyName: this.getTranslatedServiceKindString(), locality: this.locality }).toString()

  @userSelectionModule.Action(ACTIONS.userSelection.emptySelectedServiceVarietyDaysForServiceKindAsync)
  emptySelectedServiceVarietyDaysForServiceKindAsync!: (serviceKind: ServiceKind) => Promise<void>

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setCurrentStep)
  setCurrentStep!: (currentStep: number) => void

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceVarietiesAsync)
  setSelectedServiceVarietiesAsync!: (selectedServiceVarieties : ServiceVarietyName[]) => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getPropositionsByGeoLocationAsync)
  getPropositionsByGeoLocationAsync!: (payload: { serviceKind?: ServiceKind, schoolId?: string }) => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getPropositionSubscriptionsAsync)
  getPropositionSubscriptionsAsync!: (offer: { offerId: string, productIds?: string[] }) => Promise<void>

  @defaultFeatureModule.Action(ACTIONS.defaultFeature.getDefaultFeaturesAsync)
  getDefaultFeaturesAsync!: () => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedPropositionId)
  setSelectedProposition!: (selectedPropositionId : string) => void

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceAsync)
  setSelectedServiceAsync!: (selectedService: Service | undefined) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.isFormStateSpecifiedForServiceKind)
  isFormStateSpecifiedForServiceKind!: (validateDays: boolean, validateSchool: boolean, serviceKind?: ServiceKind) => boolean

  @propositionModule.Action(ACTIONS.proposition.resetStateAsync)
  resetPropositionState!: () => void

  @propositionModule.State(STATE.proposition.propositions)
  propositions?: Proposition[]

  @defaultFeatureModule.State(STATE.defaultFeature.defaultFeatures)
  defaultFeatures?: Feature[]

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.formState)
  calculatorFormState! : ICalculatorFormState

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setFormState)
  setFormState!: (formState: Partial<IUserSelectionFormState>) => void

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  get propositionsSliced (): Proposition[] {
    return this.propositions?.slice(0, this.numberOfPrositionsShown) ?? []
  }

  get hasMorePropositions (): boolean {
    const numberOfPropositions = this.propositions?.length ?? 0
    return this.numberOfPrositionsShown < numberOfPropositions
  }

  get headerTitleServiceKind () : string {
    const serviceKindString = this.getTranslatedServiceKindString()
    return serviceKindString[0].toUpperCase() + serviceKindString.slice(1)
  }

  get showOpenAvailabilitySelectorButton () : boolean {
    return !!this.userSelectionFormState.dateOfBirth || !!this.userSelectionFormState.startDateOfDayCare
  }

  readonly transitionTime = 400
  readonly pageSize = 5

  useMobileLayout = false
  isServiceMapVisible = true
  selectedPropositionId = ''
  selectedPropositionAvailablility = Availability.WaitingList
  showAvailabilitySelector = false
  numberOfPrositionsShown: number = this.pageSize
  showLoader = true
  propositionSubscriptionsLoading: Record<string, boolean> = {}
  homeLocation : google.maps.LatLngLiteral | null = null
  isAvailabilitySelectorResetted = false
  afterUpdate? : () => void

  async created (): Promise<void> {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.showAvailabilitySelector = true
    }

    await this.setSelectedServiceAsync(undefined)
    await this.getDefaultFeaturesAsync()
    await this.fetchPropositionsAsync(this.serviceKind)

    this.setHomeLocation()
  }

  mounted () : void {
    this.applyLayout()
    eventBus.$on('fetchPropositions', (serviceKind :ServiceKind) => { this.setCurrentStep(1); this.fetchPropositionsAsync(serviceKind) })
    eventBus.$emit('validateIsBSOTransitionAge')
  }

  updated () : void {
    if (this.afterUpdate) {
      this.afterUpdate()
      this.afterUpdate = undefined
    }
    this.applyLayout()
  }

  applyLayout () : void {
    if (!this.useMobileLayout && this.$vuetify.breakpoint.mdAndDown) {
      this.showAvailabilitySelector = false
    }

    if (this.useMobileLayout && !this.$vuetify.breakpoint.mdAndDown) {
      this.showAvailabilitySelector = true
    }

    this.useMobileLayout = this.$vuetify.breakpoint.mdAndDown
  }

  getHeaderTitle () : string {
    return this.headerTitleServiceKind + ' ' + this.locality
  }

  getOverlayMaskPath () : string {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 'header-overlay-mask-vertical.svg'
    } else { return 'header-overlay-mask.svg' }
  }

  resetAvailabilitySelector () : void {
    this.isAvailabilitySelectorResetted = true
    this.showAvailabilitySelector = true
  }

  onStartBookingProcess () : void {
    this.showAvailabilitySelector = false
  }

  onDaySelectorClicked (e : Event) : void {
    if (!this.showOpenAvailabilitySelectorButton || !this.isFormStateSpecifiedForServiceKind(false, true)) {
      e.preventDefault()
      this.showAvailabilitySelector = true
    }
  }

  getTranslatedServiceKindString () : string {
    switch (this.serviceKind) {
    case ServiceKind.DayCare: {
      return i18n.t('KDV').toString()
    }
    case ServiceKind.SchoolCare: {
      return i18n.t('BSO').toString()
    }
    default: return i18n.t('daycare').toString()
    }
  }

  onMarkerClicked (proposition: Proposition) : void {
    if (!this.propositions) {
      return
    }

    // Check if proposition is shown to user
    const indexOfProposition = this.propositionsSliced.findIndex(x => x.id === proposition.id)
    if (indexOfProposition >= 0) {
      this.scrollToServiceCard(proposition.id)
    } else {
      // Show all propositions until the one clicked, then scroll after it's card has been rendered
      this.afterUpdate = this.scrollToServiceCard.bind(this, proposition.id)
      Vue.set(this, 'numberOfPrositionsShown', this.propositions.findIndex(x => x.id === proposition.id) + 1)
    }
  }

  scrollToServiceCard (propositionId: string) : void {
    this.selectedPropositionId = propositionId
    this.selectedPropositionAvailablility = this.propositions?.find(x => x.id === propositionId)?.availability ?? Availability.WaitingList
    const elements = this.$refs['service-card-' + propositionId] as ServiceCard[]
    if (elements?.[0]) {
      const serviceCard = elements[0]
      serviceCard.$el.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
      serviceCard.focus()
    }
  }

  onStreetIndicatorClicked (offerId : string) : void {
    const selectedProposition = this.propositions?.find(x => x.id === offerId)
    this.selectedPropositionId = offerId
    this.selectedPropositionAvailablility = selectedProposition?.availability ?? Availability.WaitingList

    eventBus.$emit('onPanTo', selectedProposition?.latitude, selectedProposition?.longitude)
    eventBus.$emit('onMarkerClicked', offerId)
  }

  async onServiceCardActionClick (selectedProposition: Proposition): Promise<void> {
    if (!this.propositions) {
      await this.fetchPropositionsAsync(this.serviceKind)
    }
    this.setFormState({ visitedOverview: true })
    this.setSelectedProposition(selectedProposition.id)
    sendChooseLocationAnalyticsEvent(selectedProposition)
    this.$router.push({ name: ROUTES.locationDetail, params: { serviceSlug: selectedProposition.slug! } }) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  serviceMapVisibilityChanged (value : boolean) : void {
    this.isServiceMapVisible = value
  }

  onBackToTopButtonClicked () : void {
    window.scrollTo(0, 0)
  }

  onToggleSelectionPanelClicked () : void {
    this.showAvailabilitySelector = !this.showAvailabilitySelector
  }

  @Debounce(100)
  async onServiceKindChangedAsync (serviceKind : ServiceKind) : Promise<void> {
    if (this.serviceKind === ServiceKind.SchoolCare) {
      await this.setSelectedServiceVarietiesAsync([ServiceVarietyName.KDV])
    } else {
      await this.setSelectedServiceVarietiesAsync([ServiceVarietyName.NSO])
    }
    this.$router.replace({ name: ROUTES.locations, params: { serviceKind: getServiceKindParamFromServiceKind(serviceKind) } })
    this.fetchPropositionsAsync(serviceKind)
  }

  async onIncorrectAgeEnteredAsync () : Promise<void> {
    if (this.serviceKind) {
      await this.emptySelectedServiceVarietyDaysForServiceKindAsync(this.serviceKind)
      await this.onServiceKindChangedAsync(this.serviceKind === ServiceKind.DayCare ? ServiceKind.SchoolCare : ServiceKind.DayCare)
    }
  }

  async onDaySelectorChangedAsync () : Promise<void> {
    await this.fetchPropositionsAsyncDebounced()
  }

  @Debounce(600)
  fetchPropositionsAsyncDebounced () : Promise<void> {
    return this.fetchPropositionsAsync(this.serviceKind)
  }

  async fetchPropositionsAsync (serviceKind? : ServiceKind) : Promise<void> {
    this.showLoader = true

    this.setLocality()
    this.setHomeLocation()
    if (this.isFormStateSpecifiedForServiceKind(true, true, serviceKind)) {
      if (serviceKind) {
        sendLocationSearchAnalyticsEvent(serviceKind)
      }
      await this.getPropositionsAsync()
      this.getSubscriptionsForPropositions()
    } else {
      if (!serviceKind) {
        this.resetAvailabilitySelector()
      }
      const schoolId = serviceKind === ServiceKind.SchoolCare ? this.userSelectionFormState.selectedSchool?.id : undefined
      await this.getPropositionsByGeoLocationAsync({ serviceKind, schoolId })
    }

    this.showLoader = false
  }

  setLocality () : void {
    const locality = this.userSelectionFormState.postalCode?.city
    this.pageTitle = this.$t('pageTitles.locations', { serviceVarietyName: this.getTranslatedServiceKindString(), locality }).toString()
    this.metaDescription = this.$t('pageDescriptions.locations', { serviceVarietyName: this.getTranslatedServiceKindString(), locality }).toString()
  }

  setHomeLocation () : void {
    if (this.userSelectionFormState.postalCode?.latitude && this.userSelectionFormState.postalCode?.longitude) {
      this.homeLocation = { lat: this.userSelectionFormState.postalCode?.latitude, lng: this.userSelectionFormState.postalCode?.longitude }
    }
  }

  getSubscriptionsForPropositions () : void {
    for (const proposition of this.propositions ?? []) {
      this.getSubscriptionsForPropositionAsync(proposition)
    }
  }

  async getSubscriptionsForPropositionAsync (proposition: Proposition) :Promise<void> {
    if (proposition.offerId) {
      Vue.set(this.propositionSubscriptionsLoading, proposition.offerId, true)
      try {
        await this.getPropositionSubscriptionsAsync({ offerId: proposition.offerId })
      } catch (e) {
        if (!(e instanceof UnprocessablePropositionSubscriptionError)) {
          throw e
        }
      }
      Vue.set(this.propositionSubscriptionsLoading, proposition.offerId, false)
    }
  }

  showMore (): void {
    if (this.hasMorePropositions) {
      const newNumberOfPositionsShown = Math.min(
        this.numberOfPrositionsShown + this.pageSize,
        this.propositions?.length ?? 0
      )
      Vue.set(this, 'numberOfPrositionsShown', newNumberOfPositionsShown)
    }
  }
}
