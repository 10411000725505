
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import { namespace } from 'vuex-class'
import { GETTERS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import ChildBenefitCalculator from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import PropositionSubscription from '@/models/PropositionSubscription'
import { ICalculatorGetters } from '@/store/modules/childBenefitCalculator'
import { Proposition, ServiceVarietyName } from '@/models'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)

@Component({
  components: { PartouCard, PartouButton, PartouFloatingCircleButton, ChildBenefitCalculator, PartouLoader }
})
export default class PriceSummary extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerMonth)
  getBenefitsPerMonth! : ICalculatorGetters['getBenefitsPerMonth']

  @Prop({ required: true })
  proposition! : Proposition

  @Prop({ required: false })
  propositionSubscription! : PropositionSubscription

  @Prop({ required: false })
  allPropositionSubscriptions? : PropositionSubscription[]

  @Prop({ required: true })
  showFullSummary! : boolean

  @Prop({ required: false, default: false })
  isLoading! : boolean

  @Prop({ required: true })
  isOrderButtonDisabled! : boolean

  @Prop({ required: false, default: true })
  showWaitingListTip!: boolean

  get costsPerMonth (): number {
    return this.propositionSubscription && this.propositionSubscription.priceOfFirstFullMonth
  }

  get benefitsPerMonth () : number {
    return this.getBenefitsPerMonth({ offerHourlyRate: this.propositionSubscription.pricePerHour, serviceVarietyNames: this.serviceVarietyNames, propositionSubscription: this.propositionSubscription, withWaitingListHours: false })
  }

  get serviceVarietyNames () : ServiceVarietyName[] {
    return this.userSelectionFormState?.selectedServiceVarieties || []
  }

  get isPreorder () : boolean {
    return !!(this.proposition && this.proposition.isPreorder)
  }

  onOrderButtonClicked () : void {
    this.$emit('onOrderClicked')
  }
}
